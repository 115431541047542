

/* form response waiting loader start */

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
.loading-spinner {
    max-width: 70px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    position: relative;
}
.loading-spinner img {
    width: 100%;
    display: block;
}

/* form response waiting loader end */

/* Whatsapp fixed button css start */

.FixedWhatsAppButton {
    position: fixed;
    bottom: 25px;
    left: 25px;
    z-index: 99;
    width: 45px;
    height: 45px;
    display: block;
}
.FixedWhatsAppButton li {
    list-style: none;
    display: block;
    height: 100%;
    width: 100%;
}
.FixedWhatsAppButton li button {
    padding-inline: 0;
    width: 100%;
    height: 100%;
    font-size: 32px;
    cursor: pointer;
    background: #25D366;
    color: var(--white-color);
    border: none;
    line-height: 100%;
    text-align: center;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 0 #424242;
}

/* Whatsapp fixed button css end */

/* Scroll to top button css start */

.ScrollTopBtn {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 99;
    width: 45px;
    height: 45px;
    display: block;
}
.ScrollTopBtn button {
    padding-inline: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    cursor: pointer;
    background: var(--primary-yellow);
    color: var(--white-color);
    border: none;
    line-height: 100%;
    text-align: center;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 0 #424242;
}

/* Scroll to top button css end */

/* fixed contact button css start */

.HSM_ContactIconFixed {
    position: fixed;
    left: 25px;
    bottom: 80px;
    z-index: 99;
    width: 45px;
    height: 45px;
    display: block;
}
.HSM_ContactIconFixed a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
}
.HSM_ContactIconFixed a i {
    width: 100%;
    height: 100%;
    line-height: 190%;
    text-align: center;
    font-size: 24px;
    background: #41e53e;
    color: var(--white-color);
    border-radius: 100%;
    box-shadow: 0px 0px 5px 0 #424242;
}

/* fixed contact button css end */


/* Enroll Now button css start */

.HSM_EnrollNowFixed {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 99;
    background: transparent;
}
.HSM_EnrollNowFixed a {
    writing-mode: vertical-lr;
    transform: rotate(0deg);
    text-decoration: none;
    text-transform: uppercase;
    padding: 12px;
    line-height: 100%;
    background: var(--primary-orange);
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}
.HSM_EnrollNowFixed a i {
    transform: rotate(90deg);
}
.HSM_EnrollNowFixed a:hover {
    background: var(--black-color);
}

/* Enroll Now button css end */

/* header css start */

.HSM_Header {
    position: fixed;
    width: 100%;
    display: block;
    top: 0;
    z-index: 99999;
    background: transparent;
    padding-block: 10px;
    transition: all 0.3s ease-in-out;
}
.HSM_Header.sticky {
    background: var(--primary-black);
    box-shadow: 0 0 4px 0 rgb(255 255 255 / 40%);
}
.HSM_HdrAln {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.HSM_Header .HSM_HeaderLeft img {
    width: 100%;
    display: block;
    max-width: 80px;
    object-fit: contain;
}
.HSM_HeaderMenus ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}
.HSM_HeaderMenus ul li a {
    color: var(--white-color);
    text-decoration: none;
    text-transform: uppercase;
}
.HSM_HeaderMenus ul li a.active {
    color: var(--primary-yellow);
    text-decoration: overline;
}
.HSM_HeaderRight {
    display: flex;
    justify-content: end;
}
.HSM_HeaderMenus ul li {
    margin-inline: 20px;
}
.HSM_HeaderMenus ul li button {
    background: transparent;
}
.HSM_HeaderDemoBtn {
    max-width: 140px;
    width: 100%;
    display: block;
}
.HSM_HeaderCoursesContent {
    width: 100%;
    position: absolute;
    z-index: 9;
    left: 0;
    padding-block: 15px;
    background: var(--white-color);
    border: 1px solid var(
    --secondary-bg-color);
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.3s ease-in-out;
}
.HSM_HeaderCoursesContent.openMenu {
    visibility: visible;
    opacity: 1;
    transform: translateY(23px);
}
.HSM_HeaderCoursesContent.openMenu:after {
    position: absolute;
    content: '';
    display: block;
    background: var(--white-color);
    height: 20px;
    width: 20px;
    top: -20px;
    left: 30%;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    transform: translateX(-50%);
}
.HSM_HeaderCoursesContent ul li a {
    padding-block: 6px;
    border-bottom: 1px solid var(--secondary-bg-color);
}
.HSM_HeaderCoursesContent ul li:last-child a {
    border-bottom: none;
}
.HSM_HeaderMenus li.HSM_HeaderCourses a {
    cursor: pointer;
}
.HSM_HeaderCoursesContent ul {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.HSM_HeaderCoursesContent ul li {
    display: block;
    width: 100%;
    margin-inline: 0;
}
.HSM_HeaderCoursesContent ul li a {
    display: block;
    width: 100%;
    color: var(--primary-black);
}
.HSM_HeaderCoursesContentCols h6 {
    text-transform: uppercase;
    position: relative;
    display: block;
    margin-bottom: 20px;
}
.HSM_HeaderMenusMobile {
    display: none;
}
.HSM_HeaderMenusMobileClosed {
    display: block;
    overflow-y: scroll;
    position: fixed;
    background: var(--black-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out;
}
.HSM_HeaderMenusMobileClosed ul {
    margin-top: 60px;
    list-style: none;
}
.HSM_HeaderMenusMobileClosed ul li a {
    text-decoration: none;
    color: var(--white-color);
    text-align: center;
    padding-block: 20px;
}
.HSM_HeaderMenusMobileClosed ul li button {
    background: transparent;
    padding-block: 20px;
    font-size: 16px;
    line-height: 26px;
}
.HSM_HeaderMenusMobileClosed ul li a.active {
    color: var(--primary-yellow);
    text-decoration: overline;
}
.HSM_HeaderMenusMobileClosed.Opened {
    transform: translateX(0);
}
.HSM_HeaderMenusMobile span.bars {
    max-width: 40px;
    width: 100%;
    display: block;
    cursor: pointer;
}
.bar1, .bar2, .bar3 {
    height: 4px;
    background-color: var(--white-color);
    display: block;
    margin-block-end: 8px;
    border-radius: 30px;
}
.HSM_MobileMenuClose {
    position: absolute;
    right: 15px;
    top: 15px;
    display: block;
}
.HSM_MobileMenuClose span.closebtn {
    color: var(--white-color);
    font-size: 28px;
    cursor: pointer;
}
.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContent.openMenu {
    display: none;
}

/* header css end */

/* footer css start */

.HSM_Footer {
    padding-block: 60px;
    background: var(--primary-body);
}
.HSM_FooterAln {
    display: flex;
    align-items: baseline;
    padding-bottom: 60px;
    border-bottom: 1px solid var(--secondary-bg-color);
}
.HSM_FooterCols h6 {
    text-transform: uppercase;
    color: var(--white-color);
    display: block;
    margin-bottom: 25px;
    position: relative;
}
.HSM_FooterCols ul {
    list-style: none;
}
.HSM_FooterCols ul li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.HSM_FooterCols ul li i {
    color: var(--white-color);
    margin-right: 10px;
}
.HSM_FooterCols ul.HSM_FooterColsCourses {
    column-count: 2;
}
.HSM_FooterCols ul li:last-child {
	margin-bottom: 0;
}
.HSM_FooterCols ul li a {
    text-decoration: none;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}
.HSM_FooterCols ul li a.active {
    color: var(--primary-yellow);
    text-decoration: overline;
}
.SocialMedia ul {
    display: flex;
    align-items: center;
    gap: 15px;
}
.SocialMedia ul li {
    margin-bottom: 0;
}
.SocialMedia ul li a i,
.SocialMedia ul li button i {
    display: block;
    background: #adadad26;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: var(--white-color);
    box-shadow: 0 0 2px 0 #727272;
    margin-right: 0;
}
.SocialMedia ul li button {
    background: transparent;
    padding-inline: 0;
}
.HSM_FooterSocialMedia {
    width: 100%;
}
.SocialMedia {
    display: flex;
    justify-content: end;
}
.HSM_FooterSocialMedia p {
    margin-block: 25px;
    color: var(--white-color);
}
.HSM_FooterBtm {
    padding-top: 60px;
}
.HSM_FooterBtmLogo a {
    max-width: 150px;
    width: 100%;
    display: block;
    margin-inline: auto;
}
.HSM_FooterBtm p {
    text-align: center;
    color: var(--white-color);
    margin-top: 15px;
}
.HSM_FooterBtmLogo a img {
    display: block;
    width: 100%;
    object-fit: contain;
}
.HSM_WhyChooseHSM .HSM_MasteryCourseBoxCard .HSM_MasteryCourseBoxIcon {
    margin-top: 40px;
}
.HSM_WhyChooseHSM .HSM_MasteryCourseBoxCard {
    min-height: 375px;
    display: block;
    height: 100%;
}
.HSM_WhyChooseHSM button.slick-arrow.slick-prev,
.HSM_WhyChooseHSM button.slick-arrow.slick-next {
    color: var(--primary-black);
    background: var(--white-color);
}
.HSM_WhyChooseHSM .slick-prev:before, 
.HSM_WhyChooseHSM .slick-next:before {
    color: var(--primary-black);
}

/* footer css end */

/* home page css start */

.HomeBanner_Slider {
    position: relative;
}
.HomeBanner_Slider button.slick-arrow.slick-next {
    right: 25px;
    background: var(--white-color);
}
.HomeBanner_Slider button.slick-arrow.slick-prev {
    left: 25px;
    background: var(--white-color);
}
.HomeBanner_Slider button.slick-arrow.slick-prev, 
.HomeBanner_Slider button.slick-arrow.slick-next {
    top: 50%;
}
.HomeBanner_Slider .slick-prev:before, 
.HomeBanner_Slider .slick-next:before {
    color: var(--primary-black);
}
.HomeBanner_Slider .slick-slide {
    padding: 0;
}
.KSM_Banner {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: block !important;
    background-color: var(--primary-body);
}
.KSM_Banner img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.KSM_BannerTagsImg {
    max-width: 60px;
    width: 100%;
}
.KSM_Banner .container {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.KSM_Banner h1 {
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    text-shadow: -1px 1px 1px var(--white-color);
    color: var(--white-color);
}
.KSM_BannerTagsAln {
    background: rgb(0 0 0 / 70%);
    border-radius: 10px;
    padding: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
    transition: all 0.3s ease-in-out;
}
.KSM_BannerTagsAln:hover {
    background: var(--black-color);
}
.KSM_BannerTagsCont h6 {
    color: var(--white-color);
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;
}
.KSM_BannerTagsCont a {
    text-decoration: none;
    color: var(--primary-yellow);
    text-transform: uppercase;
}
.KSM_BannerTagsCont a i {
    margin-left: 5px;
}
.KSM_BannerAlg {
    position: absolute;
    width: 100%;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.HSM_CoursesSlider {
    padding-block: 60px;
}
.HSM_CoursesSlider h2 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: var(--white-color);
}
.HSM_CoursesCards {
    background: var(--white-color);
    overflow: hidden;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.HSM_CoursesCardsInner {
    padding: 20px;
}
.HSM_CoursesCards h6 {
    text-transform: uppercase;
}
.HSM_CoursesCards .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 10px;
    text-transform: uppercase;
}
.HSM_CoursesCards h6 {
    text-transform: uppercase;
    position: relative;
}
.HSM_CoursesCards .HSM_HeaderDemoBtn {
    max-width: 150px;
}
.HSM_CoursesCards p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    margin-block: 15px;
}
.HSM_CoursesBg {
    background: var(--white-color);
    position: relative;
    padding-bottom: 130px;
    padding-top: 45px;
}
.HSM_CoursesBg h2 {
    color: var(--primary-black);
}
.HSM_CoursesBg .HSM_CoursesCards {
    background: var(--white-color);
}
.HSM_CourseCardsImg {
    width: 100%;
    display: block;
    overflow: hidden;
}
.HSM_CourseCardsImg img {
    display: block;
    width: 100%;
    aspect-ratio: 6/4;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}
.HSM_CoursesCards:hover .HSM_CourseCardsImg img {
  transform: scale(1.2);
}
.HSM_MasteryCourse {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.HSM_Advantages {
    background: var(--third-gray);
}
.HSM_Advantages::before {
    background-color: rgb(0 0 0 / 35%);
}
.HSM_Advantages h2 {
    color: var(--primary-black);
}
.HSM_MasteryCourse p {
    margin-top: 20px;
    color: var(--white-color);
    text-align: center;
}
.HSM_MasteryCourse .HSM_HeaderDemoBtn {
    margin-inline: auto;
    margin-top: 30px;
    max-width: 180px;
}
.HSM_MasteryCourse .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}
.HSM_MasteryCourseListing {
    margin-top: 40px;
}
.HSM_MasteryCourseListing h6 {
    text-transform: uppercase;
    margin-bottom: 25px;
    position: relative;
}
.HSM_MasteryCourseBoxCard {
    background: var(--white-color);
    border-radius: 10px;
    padding-inline: 12px;
    padding-block: 20px;
    box-shadow: 0 0 5px 0 var(--secondary-gray);
}
.HSM_MasteryCourseBoxCard h6 {
    color: var(--primary-black);
    text-align: center;
}
.HSM_MasteryCourseBoxCard h6::before {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: -12px;
}
.HSM_MasteryCourse .HSM_MasteryCourseListing {
    margin-bottom: 40px;
}
.HSM_MasteryCourseBoxIcon {
    max-width: 70px;
    margin-inline: auto;
    display: block;
}
.HSM_MasteryCourseListing ul {
    list-style: none;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}
.HSM_MasteryCourseListing ul li h6.listingTitle {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 30px;
}
.HSM_MasteryCourseListing ul li {
    margin-bottom: 20px;
}
.HSM_MasteryCourseListing ul li:last-child {
    margin-bottom: 0;
}
.HSM_MasteryCourseListing ul li span {
    font-weight: 500;
    position: relative;
}
.HSM_MasteryCourseListing ul li span.listingText {
    font-weight: normal;
}
.HSM_MasteryCourse::before {
    background-color: rgb(0 0 0 / 70%);
}

.HSM_Tuitions {
    background: var(--white-color);
    padding-bottom: 60px;
}
.HSM_Tuitions .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-inline: auto;
    margin-top: 25px;
}
.HSM_Tuitions .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}
.HSM_Tuitions p {
    margin-top: 20px;
    text-align: center;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading {
    text-transform: uppercase;
    max-width: 290px;
}
.HSM_MasteryCourse .HSM_MasteryCourseListing ul li {
    margin-bottom: 25px;
    width: 50%;
}
.HSM_MasteryCourse .HSM_MasteryCourseListing ul li h6.listingTitle {
    margin-bottom: 20px;
}
.HSM_Tuitions h6 {
    color: var(--primary-black);
    position: relative;
    max-width: 255px;
    margin-inline: auto;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading::before,
.HSM_Tuitions h6.HSM_Tuitions_Subheading2::before {
    left: 50%;
    transform: translateX(-50%);
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading2 {
    max-width: 255px;
}
.HSM_TuitionsColsInner .HSM_CoursesCards {
    height: 160px;
    display: block;
    padding: 25px;
    transition: all 0.2s ease-in-out;
}
.HSM_TuitionsAln {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.HSM_TuitionsCols {
    margin-block: 15px;
}
.HSM_Tuitions p span {
    font-size: 16px;
}
.HSM_TuitionsColsInnerIcon {
    max-width: 50px;
    width: 100%;
    display: block;
    margin-inline: auto;
    margin-bottom: 25px;
}
.HSM_WCU {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.HSM_WCU p {
    color: var(--white-color);
    text-align: center;
}
.HSM_WCU_TabsInner p span {
    display: block;
    padding-block: 20px;
    text-transform: uppercase;
    border: 1px solid var(--secondary-bg-color);
    text-align: left;
    padding-left: 15px;
    border-radius: 10px;
}
.HSM_WCU_Inner {
    margin-top: 30px;
    margin-bottom: 30px;
}
.HSM_WCU_Tabs .HSM_CoursesCards {
    padding: 30px;
    display: none;
    /*flex-grow: 1;*/
    background: var(--secondary-bg-color);
    box-shadow: unset;
    min-height: 320px;
    height: 100%;
}
.HSM_WCU_Tabs .HSM_CoursesCards.active {
    display: block;
}
.HSM_WCU_TabsInner.active span {
    background: var(--secondary-bg-color);
}
.HSM_WCU_Tabs {
    display: flex;
    /*width: 100%;
    height: 100%;*/
    gap: 25px;
}
/*.HSM_WCU_TabButtons,
.HSM_WCU_TabContent {
    display: flex;
    flex-direction: column;
}*/
.HSM_WCU_TabButtons {
    width: 35%;
}
.HSM_WCU_TabContent {
    width: 65%;
}
.HSM_WCU_TabsInner {
    cursor: pointer;
    margin-bottom: 15px;
}
.HSM_WCU_TabsInner:last-child {
    margin-bottom: 0;
}
.HSM_CoursesCardsIcon {
    max-width: 70px;
    width: 100%;
    display: block;
    margin-inline: auto;
    margin-bottom: 15px;
}
.HSM_WCU_Tabs .HSM_CoursesCards .HSM_CoursesCardsIcon {
    margin-inline: inherit;
}
.HSM_WCU_Tabs .HSM_CoursesCards ul {
    list-style: none;
    margin-left: 0;
}
.HSM_WCU_Tabs .HSM_CoursesCards ul li {
    margin-bottom: 15px;
    color: var(--white-color);
}
.HSM_WCU_Tabs .HSM_CoursesCards ul li:last-child {
	margin-bottom: 0;
}
.HSM_WCU h6 {
    text-transform: uppercase;
    margin-bottom: 25px;
    position: relative;
    color: var(--white-color);
    max-width: 150px;
    display: block;
    margin-inline: auto;
}
.HSM_WCU h2 {
    color: var(--white-color);
}
.HSM_Advantages .HSM_MasteryCourseListing ul {
    display: block;
}
.HSM_Advantages .HSM_MasteryCourseListing ul li h6.listingTitle {
    color: var(--primary-black);
}
.HSM_Advantages .HSM_MasteryCourseListing ul li span.listingText {
    color: var(--primary-black);
}
.HSM_Testimonials {
    background: var(--third-gray);
    padding-bottom: 120px;
}
.HSM_TestimonialsCard p.HSM_TestimonialName {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}
.HSM_TestimonialsCard p.HSM_TestimonialCountry {
    text-transform: uppercase;
    font-weight: bold;
}
.HSM_TestimonialsCard p {
    text-align: center;
    margin-bottom: 15px;
}
.HSM_Testimonials_Cols {
    padding-inline: 100px;
}
.HSM_TestimonialsCardIcon {
    max-width: 130px;
    max-height: 130px;
    display: block;
    width: 100%;
    margin-inline: auto;
    margin-bottom: 25px;
    border-radius: 100%;
    height: 100%;
    padding: 15px;
    background: var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray)
}
.HSM_TestimonialsCardIcon img {
    border-radius: 100%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.HSM_AboutUs p {
    color: var(--primary-black);
    margin-top: 25px;
    text-align: center;
}
.HSM_AboutUs .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-inline: auto;
    margin-top: 40px;
}
.HSM_AboutUs .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}
.HSM_AboutUs .HSM_MasteryCourseListing {
    margin-bottom: 40px;
}

.HSM_ContactUs {
	position: relative;
    background: var(--secondary-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;  
}
.HSM_AboutUs .HSM_MasteryCourseListing ul li {
    margin-bottom: 20px;
    width: 50%;
    padding-right: 30px;
}
.HSM_Testimonials h2 {
    color: var(--primary-black);
}
.HSM_ContactUs h2 {
    color: var(--white-color);
}
.KSM_Banner::before {
    background-color: rgb(0 0 0 / 50%);
}
.HSM_ContactUs::before {
    background-color: rgb(0 0 0 / 80%);
}

.inputtypes {
    margin-bottom: 25px;
    position: relative;
}
.inputtypes span {
    color: #bb0000;
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 14px;
}
.submitform input.submit {
    background: linear-gradient(90deg, var(--primary-yellow) 60%, var(--primary-orange) 93%);
    font-size: 16px;
    border-radius: 30px;
    font-weight: 500;
    max-width: 180px;
    color: var(--white-color);
    width: 100%;
    display: block;
    padding: 20px;
    cursor: pointer;
    background-size: 200%;
    background-position: right;
    transition: all 0.3s ease-in-out;
}
.submitform input.submit:hover {
    background-position: left;
}
.HSM_ContactUsLeftInner h6 {
    color: var(--white-color);
    text-transform: uppercase;
    position: relative;
}
.HSM_ContactUsLeftInner p {
     margin-block-start: 40px;
    margin-block-end: 20px;
    color: var(--white-color);
}
.HSM_ContactDetails ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}
.HSM_ContactDetails ul li {
    display: flex;
    align-items: baseline;
    margin-block-end: 15px;
    color: var(--white-color);
}
.HSM_ContactDetails ul li span {
    text-transform: uppercase;
    margin-right: 5px;
}
.HSM_ContactDetails ul li i {
    width: 30px;
    display: block;
    color: var(--primary-yellow);
}
.HSM_ContactDetails ul li a {
    display: block;
    text-decoration: none;
    color: var(--white-color);
}
.inputtypes .react-tel-input {
    font-family: 'Rubik';
    font-size: 16px;
    color: var(--white-color);
    background: transparent;
}
.inputtypes .react-tel-input .form-control {
	background: transparent;
	border: 1.5px solid var(--secondary-bg-color);
	font-size: 16px;
	height: 52px;
    border-radius: 10px;
}
.inputtypes .react-tel-input .form-control:focus {
	border: 1px solid var(--primary-yellow);
}
.inputtypes .react-tel-input .flag-dropdown {
    background-color: transparent;
    border-right: 1.5px solid var(--secondary-bg-color);
    border-left: none;
    border-top: none;
    border-bottom: none;
}
.inputtypes .react-tel-input .selected-flag:hover, 
.inputtypes .react-tel-input .selected-flag:focus {
    background-color: transparent;
}
.inputtypes .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
    border-right: 0;
}
.inputtypes .react-tel-input .flag-dropdown.open {
    z-index: 2;
    background: transparent;
}
.inputtypes span.country-name {
    position: static;
    color: #000;
}
.inputtypes span.dial-code {
	position: static;
    color: #000;
}
.inputTwoFieldsAln {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}
.inputTwoFieldsAln .inputtypes {
    width: 50%;
}
.inputtypes select option {
	color: var(--black-color);
}
.inputtypes .css-13cymwt-control {
    min-height: 52px;
    border-color: var(--primary-black);
    border-radius: 10px;
}
.inputtypes .css-13cymwt-control:hover {
    border-color: unset;
}
.inputtypes .css-1xc3v61-indicatorContainer {
    color: var(--primary-black);
}
.inputtypes .css-1jqq78o-placeholder {
    color: var(--primary-black);
}
.inputtypes .select_menu .css-1nmdiq5--menu {
    z-index: 99;
    position: relative;
}
.inputtypes .css-t3ipsp-control {
    min-height: 52px;
    border-radius: 10px;
    border-color: var(--primary-black);
    box-shadow: unset;
}
.inputtypes .css-t3ipsp-control:hover {
    border-color: var(--primary-black);
}
.HSM_ContactUsRightInner p {
    margin-bottom: 30px;
    color: var(--white-color);
}
.HSM_ContactUsRightInnerListings {
    margin-block: 30px;
}
.HSM_ContactUsRightInnerListings ul {
    list-style: circle;
    margin-left: 15px;
}
.HSM_ContactUsRightInnerListings ul li {
	margin-bottom: 10px;
    color: var(--white-color);
}
.HSM_ContactUsRightInnerListings ul li:last-child {
	margin-bottom: 0;
}
.HSM_ContactUs p.HSM_ContactUsBtm {
    text-align: center;
    margin-top: 40px;
    color: var(--white-color);
}
.HSM_Faq {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: var(--white-color);
}
.HSM_Faq::before {
    background-color: rgb(0 0 0 / 80%);
}
.HSM_Faq_Accordian {
    margin-block-end: 40px;
}
.HSM_Faq .HSM_HeaderDemoBtn {
    margin-inline: auto;
    max-width: 180px;
}
.HSM_Faq .HSM_HeaderDemoBtn .ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}
.HSM_Faq_Accordian_Item {
    margin-block-end: 15px;
}
.HSM_Faq_Accordian_Item:last-child {
    margin-bottom: 0;
}
.HSM_Faq_Accordian_Item.active button.HSM_Faq_Accordian_Question {
    border: 1.5px solid var(--secondary-bg-color);
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
    background: var(--secondary-bg-color);
    border-radius: 10px 10px 0 0;
}
.HSM_Faq_Accordian_Answer.active {
    opacity: 1;
    height: auto;
    border: 1.5px solid var(--secondary-bg-color);
    padding-inline: 20px;
    color: var(--white-color);
    padding-block: 20px;
    border-top: 0;
    border-radius: 0 0 10px 10px;
}
button.HSM_Faq_Accordian_Question {
    font-size: 18px;
    line-height: 28px;
    padding-inline-start: 20px;
    padding-inline-end: 45px;
    padding-block: 15px;
    text-align: left;
    color: var(--white-color);
    background: transparent;
    border: 1.5px solid var(--secondary-bg-color);
    outline: none;
    display: block;
    width: 100%;
    position: relative;
    transition: all 0.4s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
}
.HSM_Faq_Accordian_Answer {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}
.HSM_Faq_Accordian_Answer p {
    color: var(--white-color);
}
button.HSM_Faq_Accordian_Question:before {
    position: absolute;
    right: 20px;
    top: 50%;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 20px;
    transform: translateY(-50%);
}
.HSM_Faq_Accordian_Item.active button.HSM_Faq_Accordian_Question:before {
    content: '\f106';
    font-family: 'FontAwesome';
}
.HSM_BookDemo h2 {
    color: var(--primary-black);
}
.HSM_BookDemo .HSM_ContactUsRightInner p {
    color: var(--primary-black);
}
.HSM_BookDemo .HSM_ContactUsRightInnerListings ul li {
    color: var(--primaary-black);
}
.HSM_Faq h2 {
    color: var(--white-color);
}
.HSM_BookDemo {
    background: var(--white-color);
}
.HSM_BookDemo input, 
.HSM_BookDemo textarea,
.HSM_BookDemo select {
    border: 1.5px solid var(--primary-black);
    color: var(--primary-black);
    background: transparent;
}

.HSM_BookDemo input::-webkit-input-placeholder, 
.HSM_BookDemo textarea::-webkit-input-placeholder  {
  color: var(--primary-black);
}

.HSM_BookDemo input:-ms-input-placeholder, 
.HSM_BookDemo textarea:-ms-input-placeholder {
  color: var(--primary-black);
}

.HSM_BookDemo input::placeholder, 
.HSM_BookDemo textarea::placeholder {
  color: var(--primary-black);
}

.HSM_BookDemo input:-webkit-autofill {
    -webkit-text-fill-color: var(--primary-black);
}

.HSM_BookDemo .inputtypes .react-tel-input {
    font-family: 'Rubik';
    font-size: 16px;
    color: var(--white-color);
    background: transparent;
}
.HSM_BookDemo .inputtypes .react-tel-input .form-control {
    border: 1.5px solid var(--primary-black);
}
.HSM_BookDemo .inputtypes .react-tel-input .form-control:focus {
    border: 1px solid var(--primary-black);
}
.HSM_BookDemo .submitform input.submit {
    border: none;
    margin-inline: auto;
}
.inputTypesBoxes .inputtypes img.imgPreview {
    max-width: 120px;
    display: block;
    margin-top: 15px;
    object-fit: contain;
}

.HSM_MasteryCourse h2 {
    color: var(--white-color);
}
.HSM_TuitionsColsInner p {
    text-align: center;
    color: var(--primary-black);
}
.HSM_AboutUs {
    background: var(--white-color);
}
.HSM_AboutUs h2 {
    color: var(--primary-black);
}
.HSM_Tuitions h2 {
    color: var(--primary-black);
}
.HSM_WCU {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.HSM_WCU::before {
    background-color: rgb(0 0 0 / 70%);
}
.HSM_Advantages .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-inline: auto;
    margin-top: 85px;
}
.HSM_Advantages .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}

.formOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
.popup {
    background-color: var(--third-gray);
    padding: 30px;
    max-width: 400px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    position: relative;
}
.popup button.close_btn {
    background: var(--white-color);
    color: red;
    width: 40px;
    height: 40px;
    display: block;
    padding-inline: 0;
    border-radius: 100%;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right: -18px;
    top: -18px;
}
.popup p {
    margin-block-start: 20px;
    margin-bottom: 0;
    color: var(--primary-black);
}
.popup-check-right {
    max-width: 60px;
    width: 100%;
    display: block;
    margin-inline: auto;
    margin-top: -60px;
}
.popup-check-right i {
    background: var(--white-color);
    color: #1cb51c;
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    font-size: 24px;
    margin-inline: auto;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}

.HSM_Latest_BlogCardInner {
    background: var(--white-color);
    border-radius: 10px;
    overflow: hidden;
    min-height: 485px;
    height: 100%;
}
.HSM_LatestAllBlogBtn {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.HSM_LatestAllBlogBtn .HSM_HeaderDemoBtn {
    max-width: 180px;
}
.HSM_LatestAllBlogBtn h4 {
    color: var(--white-color);
    text-transform: uppercase;
    margin-bottom: 25px;
    font-family: 'Cormorant Garamond';
}
.HSM_LatestAllBlogBtn .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
}
.HSM_Latest_BlogCardInnerImg {
    width: 100%;
    display: block;
}
.HSM_Latest_BlogCardInnerImg img {
    display: block;
    width: 100%;
    aspect-ratio: 5/3;
    object-fit: cover;
}
.HSM_Latest_BlogCardInnerContent h6 {
    max-width: unset;
    color: var(--primary-black);
    text-transform: capitalize;
}
.HSM_Latest_BlogCardInnerContent {
    padding: 20px;
}
.HSM_Latest_BlogCardInnerContent p {
    color: var(--primary-black);
    text-align: left;
    margin-block-start: 30px;
    margin-block-end: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    flex: 1 1;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.HSM_Training_Center h6 {
    max-width: 42%;
}
.HSM_Training_Center h6::before {
    left: 50%;
    transform: translateX(-50%);
}
.HSM_Training_Center h6 span {
    color: var(--primary-yellow);
    font-style: italic;
    font-family: 'Cormorant Garamond';
    font-size: 24px;
}
.HSM_Training_Center h4 {
    text-align: center;
    color: var(--primary-yellow);
    font-style: italic;
    font-family: 'Cormorant Garamond';
    font-size: 36px;
    text-transform: uppercase;
    margin-block-start: 40px;
}
.HSM_Training_Center .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-inline: auto;
}
.HSM_Training_Center .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
}
.HSM_Training_CenterDesc {
    margin-block: 30px;
}
.HSM_Training_CenterDesc p {
    margin-bottom: 10px;
    font-family: 'Cormorant Garamond';
    font-size: 24px;
    color: var(--white-color);
    font-weight: 500;
}
.HSM_Training_CenterDesc p:last-child {
    margin-bottom: 0;
}

/* home page css end */

/* application form page css start */

.HSM_ApplicationForm {
    background: var(--third-gray);
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li h6.listingTitle {
    color: var(--primary-black);
    margin-bottom: 20px;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li span.listingText {
    color: var(--primary-black);
}
.HSM_ApplicationForm .HSM_ContactUsRightInner p {
    text-align: center;
    margin-bottom: 0;
}
.HSM_ApplicationForm .HSM_ContactUsRightInner p.HSM_ApplicationFormBankInst {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.HSM_ApplicationForm .HSM_ContactUsRightInner p.HSM_ApplicationFormBankInst span {
    color: red;
    margin-right: 5px;
    font-size: 20px;
}
.HSM_ApplicationForm .HSM_ContactUsRightInner h5 {
    text-align: center;
    margin-block: 40px;
}
.HSM_ApplicationForm form {
    max-width: 550px;
    display: block;
    margin-inline: auto;
    width: 100%;
    margin-block: 40px;
}
.HSM_ApplicationFormTC {
    margin-block: 30px;
}
.HSM_ApplicationFormTC p {
    margin-bottom: 20px;
}
.HSM_ApplicationForm .HSM_ApplicationFormNote p {
    text-align: left;
    margin-bottom: 0;
    color: var(--white-color);
}
.HSM_ApplicationFormNote span {
    color: red;
    font-weight: 500;
}
.HSM_ApplicationFormNote {
    margin-block-start: 35px;
    background: var(--primary-black);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 35px;
}
.inputtypes label span.LabelReq {
    position: static;
    font-size: 16px;
}
.inputtypes label {
    margin-bottom: 10px;
    display: block;
}
.inputTypesBoxes .inputtypes label {
    margin-bottom: 20px;
    display: block;
    text-transform: uppercase;
    line-height: 24px;
}
.inputTypesBoxes .inputtypes label.checkedT\&C {
    text-transform: inherit;
    margin-bottom: 0;
}
.admissionFormT\&C .inputtypes span.errorForm {
    position: relative;
    top: -7px;
}
.admissionFormT\&C .inputtypes {
    margin-bottom: 10px;
}
.termsCheckedLabel {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.termsCheckedLabel input {
    max-width: 15px;
    width: 100%;
    display: block;
    margin-block: 3px;
}
.inputTypesBoxes .inputtypes label.genderSelect {
    text-transform: capitalize;
    margin-bottom: 0;
}
.genderInputs label {
    margin-bottom: 0px;
}
.inputTypesBoxes {
    padding: 25px;
    margin-bottom: 15px;
    border-radius: 10px;
    background: var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.inputTypesBoxes .inputtypes {
    margin-bottom: 0;
}
.inputTypesBoxes .inputtypes span.errorForm {
    top: 8px;
    position: relative;
}
/*.inputTypesBoxes .inputtypes input, 
.inputTypesBoxes .inputtypes textarea, 
.inputTypesBoxes .inputtypes select {
	padding: 10px;
}*/
.inputTypesBoxes .inputtypes .react-tel-input .form-control {
	padding-left: 48px;
}
.inputTypesBoxes .inputtypes .react-tel-input .flag-dropdown.open .selected-flag {
    border-right: 0;
}
.inputTypesBoxes .css-1nmdiq5-menu {
    position: static;
    border-radius: 0;
    box-shadow: unset;
    margin-bottom: 10px;
    margin-top: 10px;
}
.inputTypesBoxes .css-d7l1ni-option {
    background: transparent;
}
.inputTypesBoxes .select__option label {
    text-transform: capitalize;
    margin-bottom: 0;
}
.inputTypesBoxes .select__option {
    display: flex;
    align-items: center;
    gap: 10px;
}
.inputTypesBoxes .inputtypes .css-13cymwt-control {
    min-height: 52px;
    border-color: var(--primary-black);
    border-radius: 10px;
    /*display: none;*/
}
.inputTypesBoxes .select__option input {
    max-width: 20px;
}
.inputTypesBoxes .css-tr4s17-option {
    background-color: transparent;
    color: var(--primary-black);
}
.inputTypesBoxes .css-tr4s17-option:active {
    background-color: transparent;
}
.inputTypesBoxes .css-1n6sfyn-MenuList {
    max-height: unset;
}
.genderInputs {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.genderInputs p {
	margin-bottom: 0;
}
.genderInputs input {
    width: auto;
    margin-right: 15px;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li {
    width: 32%;
    background: var(--white-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    min-height: 225px;
    height: 100%;
    margin-bottom: 0;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li:last-child:before {
    display: none;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul {
    gap: 25px;
    justify-content: center;
}
.HSM_ApplicationForm .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-top: 15px;
}
.HSM_Terms_Conditions_Btn.HSM_HeaderDemoBtn {
    max-width: 200px;
    margin-top: 30px;
    margin-inline: auto;
}
.HSM_ApplicationForm .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
    text-transform: uppercase;
}
.HSM_ApplicationFormBtm p {
    margin-top: 20px;
}
.HSM_ApplicationFormGuideIcon {
    max-width: 60px;
    display: block;
    width: 100%;
    margin-block-end: 15px;
    margin-top: 15px;
}
.HSM_ApplicationFormGuideIcon img {
    display: block;
    width: 100%;
}
.HSM_BankDetailsAln h5 {
    margin-block: 0 !important;
    text-align: left !important;
    text-transform: uppercase;
    margin-bottom: 20px !important;
}
.HSM_BankDetailsLeftInner {
    display: flex;
    align-items: baseline;
    gap: 20px;
}
.HSM_BankDetailsColsCard {
    background: var(--white-color);
    border-radius: 10px;
    padding: 35px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.HSM_BankDetailsColsCard h6 {
    position: relative;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.HSM_BankDetailsColsCard p {
    text-align: left !important;
}
.HSM_BankDetailsColsCardBank {
    margin-bottom: 25px;
}
.HSM_BankDetailsColsCardBank:last-child {
    margin-bottom: 0;
}
.HSM_BankDetailsColsCardBankAlgn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyIcon {
    cursor: pointer;
}
.HSM_BankDetailsColsCardBankInner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
}
.HSM_BankDetailsColsCardBankUPI {
    width: 50%;
}
.HSM_BankDetailsColsCardBankUPI_QR {
    width: 50%;
}
.PaymentTypeImg {
    display: block;
    max-width: 70px;
    width: 100%;
    margin-block-start: 10px;
    margin-block-end: 10px;
}
.HSM_BankDetailsColsCardBankUPI_QR img,
.PaymentTypeImg img {
    display: block;
    width: 100%;
    object-fit: contain;
}
.HSM_BankDetailsLeftInner .HSM_BankDetailsColsCard {
    margin-block-end: 15px;
}
.HSM_BankDetailsLeftInner .HSM_BankDetailsColsCard:last-child {
    margin-block-end: 0;
}
.copied {
  color: var(--primary-yellow);
}
.HSM_ApplicationFormProcessNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.HSM_ApplicationFormProcessNum {
    width: 30px;
    text-align: center;
    background: var(--primary-yellow);
    color: var(--white-color);
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
}

/* application form page css end */

/* about us page css start */

.HSM_AboutProfileBanner {
    position: relative;
}
.HSM_AboutProfileBanner::before {
    background-color: rgb(0 0 0 / 30%);
}
.HSM_AboutProfile h3 {
    text-align: center;
    margin-bottom: 15px;
}
.HSM_AboutProfile p {
    margin-top: 0;
    margin-bottom: 25px;
}
.HSM_AboutProfile .HSM_MasteryCourseListing {
    margin-top: 15px;
    margin-bottom: 0;
}
.HSM_AboutProfile .HSM_MasteryCourseListing ul li {
    margin-bottom: 0;
    margin-top: 25px;
}
.HSM_AboutProfileVision {
    margin-block: 40px;
}
.HSM_AboutProfileBannerColsCard {
    padding: 20px;
    background: var(--secondary-bg-color);
    border-radius: 10px;
}
.HSM_AboutProfileBannerColsCard img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 10px;
}
.HSM_AboutProfileBannerCols p {
    color: var(--white-color);
    margin-bottom: 15px;
}
.HSM_AboutProfileBannerCols p:last-child {
    margin-bottom: 0;
}
.HSM_AboutProfileBannerAln {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.HSM_AboutProfileBannerCols h2 {
    text-align: left;
}
.HSM_AboutProfileBannerCols h6 {
    position: relative;
    color: var(--white-color);
    text-transform: uppercase;
    margin-bottom: 30px;
}
.HSM_AboutProfileBannerCols h6 span.HSM_AboutProfileSpan1 {
    text-transform: lowercase;
    display: inline-block;
    margin-inline: 5px;
}
.HSM_AboutProfileBannerCols h6 span.HSM_AboutProfileSpan2 {
    color: var(--primary-yellow);
    font-family: 'Cormorant Garamond';
    font-size: 24px;
    font-style: italic;
}
.HSM_AboutProfile {
    padding-top: 45px;
}
.HSM_About_Vision .HSM_AboutProfileVision {
    margin-block: 0;
}
.HSM_About_Vision p {
    margin-bottom: 0;
}
.HSM_About_Vision_Tp {
    padding-bottom: 30px;
    background: var(--white-color);
}
.HSM_About_Vision {
    background: var(--third-gray);
    padding-block: 60px;
}

/* about us page css end */

/* particular course page css start */

.KSM_IndianClassical_Banner {
    position: relative;
}
.HSM_Course_Particular {
    background: var(--third-gray);
}
.HSM_CoursePartiular .HSM_MasteryCourseListing {
    margin-top: 0;
}
.HSM_CoursePartiular .HSM_TuitionsCols {
    margin-block: 15px;
}
.HSM_CoursePartiular .HSM_TuitionsColsInner .HSM_CoursesCards {
    height: auto;
}
.HSM_CoursePartiular h6 {
    max-width: unset;
    text-align: center;
}
.HSM_CoursePartiular h6::before {
    left: 50%;
    transform: translateX(-50%);
}
.HSM_CoursePartiular .HSM_TuitionsColsInner .HSM_CoursesCards h2 {
    font-family: 'Rubik';
    margin-bottom: 0;
}
.HSM_CoursePartiular {
    padding-bottom: 0;
}
.HSM_Course_particularDescInner h6 {
    max-width: unset;
    text-transform: uppercase;
    font-size: 22px;
}
.HSM_Course_particularDescInner p {
    text-align: left;
}
.HSM_Course_particularDescInner ul {
    list-style: circle;
    margin-left: 20px;
    display: block;
    margin-top: 20px;
}
.HSM_Course_particularDescInner {
    margin-bottom: 25px;
}
.HSM_Course_particularDescInner:last-child {
    margin-bottom: 0;
}
.HSM_Course_particularSelect h5 {
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
}
.HSM_Course_particularSelect {
    margin-block-start: 40px;
}
.HSM_Course_particularSelectBtn h6 {
    max-width: unset;
    text-align: center;
    text-transform: uppercase;
}
.HSM_Course_particularSelectBtn h6::before {
    left: 50%;
    transform: translateX(-50%);
}
.HSM_Course_particularSelectAln {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 25px;
}
.HSM_Course_particularSelectBtn {
    background: var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    padding: 35px;
    width: 150px;
    max-width: 100%;
    height: 150px;
    max-height: 100%;
    line-height: 100%;
    text-align: center;
    border-radius: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_particularSelectBtn:hover {
    background: var(--primary-black);
}
.HSM_Course_particularSelectBtn:hover h6 {
    color: var(--white-color);
}
.HSM_Course_particularSelectBtn.active {
    background: var(--primary-black);
}
.HSM_Course_particularSelectBtn.active h6 {
    color: var(--white-color);
}
.HSM_Course_paricularSelectedSolo,
.HSM_Course_paricularSelectedGroup {
    display: none;
}
.HSM_Course_paricularSelectedSolo.active,
.HSM_Course_paricularSelectedGroup.active {
    display: block;
}
.HSM_Course_paricularSelectedSolo h6,
.HSM_Course_paricularSelectedGroup h6 {
    max-width: unset;
    text-transform: uppercase;
    margin-block-start: 25px;
    margin-block-end: 25px;
    font-size: 22px;
}
.HSM_Course_particularSelect p {
    margin-top: 5px;
}
.HSM_Course_paricularSelectedSolo ul,
.HSM_Course_paricularSelectedGroup ul {
    list-style: none;
    display: block;
    max-height: 170px;
    overflow: auto;
    height: 100%;
    margin-block: 25px;
}
.HSM_Course_paricularSelectedSolo ul li,
.HSM_Course_paricularSelectedGroup ul li {
    margin-bottom: 10px;
}
.HSM_Course_paricularSelectedCardcolsInner ul li {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: 10px;
}
.HSM_Course_paricularSelectedSolo ul li i.fa.fa-check {
    color: #079507;
}
.HSM_Course_paricularSelectedSolo ul li i.fa.fa-close {
    color: #e71313;
}
.HSM_Course_paricularSelectedSolo ul li:last-child,
.HSM_Course_paricularSelectedGroup ul li:last-child {
    margin-bottom: 0;
}
.HSM_Course_paricularSelectedSolo ul li span.HSM_Course_particularListTitle,
.HSM_Course_paricularSelectedGroup ul li span.HSM_Course_particularListTitle {
    width: 180px;
    display: inline-block;
    text-transform: capitalize;
    font-weight: 500;
}
.HSM_Course_paricularSelectedCardAln {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}
p.HSM_groupCourse {
    margin-bottom: 40px;
}
.HSM_Course_Virtuoso.HSM_Course_paricularSelected .HSM_Course_paricularSelectedCardAln {
    margin-top: 90px;
}
.HSM_Course_paricularSelectedCardcols {
    margin-block: 15px;
}
.HSM_Course_paricularSelectedCardcolsInner {
    background: var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    border-radius: 10px;
    padding: 30px;
}
.HSM_Course_paricularSelectedCardcolsInner h5 {
    text-transform: uppercase;
    margin-bottom: 25px;
    background: var(--primary-black);
    color: var(--white-color);
    padding: 12px;
    text-align: center;
    border-radius: 10px;
}
.HSM_Course_paricularSelectedCardcolsInnerPay {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.HSM_Course_paricularSelectedCardcolsInnerPayBtn a {
    background: var(--primary-black);
    color: var(--white-color);
    text-decoration: none;
    display: block;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_paricularSelectedCardcolsInnerPayBtn {
    margin-bottom: 10px;
    max-width: 130px;
    width: 100%;
    display: block;
}
span.pricePerClass {
    font-size: 14px !important;
    color: #7c7c7c;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight .HSM_Course_paricularSelectedCardcolsInnerPayBtn {
    text-align: right;
    margin-left: auto;
    max-width: 160px;
}
.HSM_Course_paricularSelectedCardcolsInnerPayLeft {
    width: 55%;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight {
    width: 45%;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight .HSM_HeaderDemoBtn {
    display: block;
    margin-inline: unset;
    margin-left: auto;
    max-width: 140px;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 10px;
    text-transform: capitalize;
}
.HSM_Course_paricularSelectedCardcolsInnerPayBtn span {
    font-size: 26px;
    margin-left: 5px;
    font-weight: 500;
}
.HSM_Course_paricularSelectedCardcolsInnerPayBtn:last-child {
    margin-bottom: 0;
}
.HSM_Course_paricularSelectedCardcolsInnerPayBtn a.ankrBtn {
    background: linear-gradient(90deg, var(--primary-yellow) 60%, var(--primary-orange) 93%);
    border-radius: 30px;
}
.HSM_Course_paricularSelectedCardcolsInner p {
    text-align: left;
    max-height: 100px;
    height: 100%;
    display: block;
    overflow: auto;
}
.HSM_Course_paricularSelectedGroup .HSM_Course_paricularSelectedCardcolsInner p {
    min-height: auto;
}

.HSM_Course_paricularSelectedCardcolsInnerTable table {
    width: 100%;
    margin-block: 30px;
}
.HSM_Course_paricularSelectedCardcolsInnerTable table th {
    text-align: left;
    text-transform: uppercase;
}
.HSM_Course_paricularSelectedCardcolsInnerTable table td i.fa.fa-check {
    color: #079507;
}
.HSM_Course_paricularSelectedCardcolsInnerTable table td i.fa.fa-close {
    color: #e71313;
}
.HSM_Course_paricularSelectedCardcolsInnerTable table, 
.HSM_Course_paricularSelectedCardcolsInnerTable th, 
.HSM_Course_paricularSelectedCardcolsInnerTable td {
  border: 1px solid var(--primary-black);
  border-collapse: collapse;
}
.HSM_Course_paricularSelectedCardcolsInnerTable th, 
.HSM_Course_paricularSelectedCardcolsInnerTable td {
    padding: 15px;
}
.HSM_CourseGroupAlgn {
    margin-block: 30px;
}
.HSM_CourseGroupAlgn h6::before {
    left: 50%;
    transform: translateX(-50%);
}

.HSM_CourseGroupAlgn h6 {
    max-width: unset;
    text-align: center;
    margin-inline: unset;
    margin-block: 0;
}
.HSM_Course_paricularSelectedGroup .HSM_ApplicationFormNote {
    margin-block-start: 0;
    margin-bottom: 0;
}
.HSM_Course_paricularSelectedGroup .HSM_ApplicationFormNote p {
    margin-top: 0;
    text-align: left;
    color: var(--white-color);
}
.HSM_Course_paricularSelectedCardcolsInnerPayLeft .HSM_Course_paricularSelectedCardcolsInnerPayBtn a {
    padding: 10px;
}
.HSM_Course_paricularSelectedCardcolsInner h6 {
    font-size: 18px;
}
.HSM_CorseGroupTime span {
    background: var(--primary-black);
    color: var(--white-color);
    padding: 10px;
    display: inline-block;
    width: 40px;
    border-radius: 10px;
}
.HSM_Course_paricularSelectedCardcolsInnerSessions h6 {
    margin-block: 0;
}
.HSM_Course_paricularSelectedCardcolsInnerSessions p {
    min-height: auto;
    margin-block-end: 25px;
}
.HSM_Course_paricularSelectedSolo h5 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
}
.HSM_Course_paricularSelectedSolo h5.HSM_Course_paricularSelectedSoloTitle {
    margin-top: 40px;
}
.HSM_Course_paricularSelectedSoloInstructions {
    padding: 30px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_particularContent {
    display: none;
    align-items: start;
    flex-wrap: wrap;
    gap: 40px;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_InstArrow {
    max-width: 100px;
    display: block;
    margin-inline: auto;
    text-align: center;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_InstArrow i {
    background: var(--white-color);
    color: var(--primary-black);
    padding: 30px;
    border-radius: 100%;
    font-size: 22px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.HSM_Course_paricularSelectedSoloInstructions.active {
    border: 1px solid var(--primary-black);
}
.HSM_Course_particularContent.active {
    display: flex;
    margin-bottom: 25px;
}
.HSM_Course_InstArrow.active i {
    transform: rotate(180deg);
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar.FullWidth {
    width: 100%;
    margin-top: -25px;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar p {
    margin-top: 0;
    text-align: left;
    margin-bottom: 20px;
    width: 50%;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar p:last-child {
    margin-bottom: 0;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar p span.starSingle {
    text-transform: uppercase;
    font-weight: 500;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar p a {
    display: inline-block;
}
.HSM_WesternVocalsGroupAdv h6 {
    font-size: 18px;
    margin-top: 0;
}
.HSM_WesternVocalsGroupAdv .HSM_CoursesCards {
    height: auto;
    min-height: 275px;
}
.HSM_WesternVocalsGroupAdv {
    margin-block-start: 40px;
}
.HSM_WesternVocalsGroupAdv .HSM_CoursesCards ul {
    margin-bottom: 0;
    max-height: none;
    list-style: circle;
    overflow: unset;
    margin-left: 15px;
}
.HSM_WesternVocalsGroupAdv h2 {
    margin-bottom: 20px;
}
p.HSM_groupCourse {
    text-align: left;
}
.HSM_Course_paricularSelectedCardcolsInner ul li span {
    color: #079507;
    width: 50%;
    display: flex;
    justify-content: end;
    text-align: right;
}
.HSM_Course_Virtuoso p {
    text-align: left;
}
.HSM_Course_Virtuoso ul.virtuosoAdvantagesListing {
    max-height: unset;
    overflow: unset;
    margin-bottom: 0;
}
.HSM_Course_Virtuoso ul {
    max-height: unset;
}
.HSM_Course_Virtuoso ul.virtuosoAdvantagesListing li {
    margin-bottom: 0;
    padding: 25px;
    min-height: 305px;
    background: var(--white-color);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.HSM_Course_Virtuoso ul.virtuosoAdvantagesListing li span.HSM_Course_particularListTitle {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    margin-bottom: 20px;
}
.HSM_Course_Virtuoso ul.virtuosoAdvantagesListing li p {
    display: block;
    margin-top: 0;
    width: 80%;
}
h5.vistuosoCoursAdvantagesTitle {
    text-align: left;
    font-size: 18px;
    margin-top: 25px;
}
span.CoursePrice {
    font-size: 22px;
    text-decoration: line-through;
}
span.discountedCoursePrice {
    color: var(--primary-yellow);
}
ul.HSM_VirtousoCourseListing {
    margin-top: 0;
}
ul.HSM_VirtousoCourseListing li {
    display: flex;
    justify-content: start;
}
ul.HSM_VirtousoCourseListing li span.HSM_VirtousoCourseListingTitle {
    color: var(--primary-black);
    font-weight: 500;
    width: 150px;
    text-align: left;
    justify-content: start;
}
.HSM_Course_Virtuoso p {
    text-align: left;
}
p.vistuosoCourseCardBtm {
    text-align: center;
}
ul.HSM_VirtousoCourseListing p {
    margin-top: 0;
}
.HSM_WhatWillBeTaught ul li {
    display: block;
    margin-right: 0;
    position: relative;
    padding-left: 25px;
}
.HSM_WhatWillBeTaught ul li:before {
    background: var(--primary-black);
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 100%;
}

/* paricular course page css end */

/* FAQ page css start */

.HSM_FaqPage_bg::before {
    background: var(--third-gray);
}
.HSM_FaqPage_bg h2 {
    color: var(--primary-black);
}
.HSM_FaqPage_bg .HSM_Faq_Accordian_Item.active button.HSM_Faq_Accordian_Question {
    background: var(--white-color);
    border: 1px solid var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.HSM_FaqPage_bg button.HSM_Faq_Accordian_Question {
    color: var(--primary-black);
    border: 1px solid var(--primary-black);
}
.HSM_FaqPage_bg .HSM_Faq_Accordian_Answer p {
    color: var(--primary-black);
}
.HSM_FaqPage_bg .HSM_Faq_Accordian_Answer.active {
    border: 1px solid var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.HSM_FAQ_Banner {
    background-color: var(--white-color);
}

/* FAQ page css end */

/* blog page css start */

.KSM_AllBlogMain {
    background: var(--third-gray);
    padding-block: 60px;
}
.KSM_AllBlgLeftCardInnerContent h6 {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 10px;
    font-size: 16px;
}
.KSM_AllBlgLeftCardInnerContent h6 span {
    color: var(--primary-yellow);
    text-transform: capitalize;
    font-weight: 500;
}
.KSM_AllBlgLeftCard {
    background: var(--white-color);
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.KSM_AllBlgLeftCard:last-child {
    margin-bottom: 0;
}
.KSM_AllBlgLeftCardInner {
    display: flex;
    align-items: flex-start;
    gap: 30px;
}
.KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerContent {
    width: 80%;
    display: block;
}
.KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerImg {
    width: 20%;
    display: block;
}
.KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerImg img {
    width: 100%;
    display: block;
    object-fit: cover;
    aspect-ratio: 5/4;
    border-radius: 10px;
}
.KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerContent h5 {
    margin-bottom: 15px;
}
.KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerContent p {
    margin-block-start: 25px;
    margin-block-end: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.KSM_AllBlogRight h5 {
    text-transform: uppercase;
    margin-bottom: 15px;
}
.KSM_AllBlogRightCard {
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    background: var(--white-color);
    border-radius: 10px;
    padding: 15px;
}
.KSM_AllBlogRightCardInner {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
}
.KSM_AllBlogRightCardInner:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerImg {
    width: 35%;
    display: block;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerImg img {
    width: 100%;
    object-fit: cover;
    display: block;
    aspect-ratio: 1/1;
    border-radius: 10px;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerContent {
    width: 65%;
    display: block;
}
.HSM_CourseReadMore {
    max-width: 90px;
    display: block;
    width: 100%;
}
.HSM_CourseReadMore a.ankrBtn {
    font-size: 12px;
    padding: 3px;
}
.KSM_AllBlogRightCardInnerContent h6 {
    position: relative;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}
.KSM_AllBlogRightInner {
    margin-bottom: 30px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 30px;
}
.KSM_AllBlogRightInner:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerContent p {
    line-height: 14px;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerContent p span {
    font-size: 14px;
    color: #606060;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerContent a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.KSM_AllBlogRightCardInner .KSM_AllBlogRightCardInnerContent a:hover h6 {
    color: var(--primary-yellow);
}
.KSM_ALLBlogRightInnerCategory form select {
    background: var(--white-color);
    color: var(--primary-black);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.KSM_BlogPagination {
    margin-top: 50px;
}
.KSM_BlogPagination ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
}
.KSM_BlogPagination ul li .page-link[disabled]:hover {
    cursor: not-allowed;
}
.KSM_BlogPagination ul li button {
    background: var(--white-color);
    padding: 15px;
    font-size: 14px;
    color: var(--primary-black);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
}
.KSM_BlogPagination ul li.active button {
    background: var(--primary-black);
    color: var(--white-color);
}
.KSM_BlogPagination ul li.prev-btn button,
.KSM_BlogPagination ul li.next-btn button {
    width: 110px;
}
.KSM_AllBlogMain p.KSM_AllBlogMainpara {
    text-align: center;
    margin-bottom: 50px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 40px;
}
.KSM_AllBlogMain p.KSM_AllBlogMainparabtm {
    text-align: center;
    margin-top: 50px;
    border-top: 1px solid #dbdbdb;
    padding-top: 40px;
}

/* blog page css end */

/* blog single page css start */

.KSM_SingleBlogCardInner {
    display: block;
}
.KSM_SingleBlogCardInner h4 {
    text-transform: uppercase;
    font-family: 'Cormorant Garamond';
}
.KSM_SingleBlogCardInner h6 {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;
}
.KSM_SingleBlogCardInner p a {
    display: inline-block;
    color: blue;
}
.KSM_SingleBlogCardInner h5 {
    text-transform: uppercase;
    color: var(--primary-yellow);
}
.KSM_Blog_SingleMain h5.cateName {
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
}
.KSM_Blog_SingleMain h5.cateName span {
    color: var(--primary-yellow);
}
.KSM_Blog_SingleMain p.postPostedDate {
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
}
.KSM_Blog_SingleMain p.postPostedDate span {
    text-align: center;
    color: #606060;
    font-weight: 400;
}

/* blog single page css end */

/* insta feed section css start */

.HSM_InstaFeed .KSM_AllBlgLeftCardInner {
    gap: 0;
}
.HSM_InstaFeed .KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerContent {
    width: 100%;
}
.HSM_InstaFeed .KSM_AllBlgLeftCardInner .KSM_AllBlgLeftCardInnerContent img {
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.HSM_InstaFeed h2 {
    text-align: center;
    margin-bottom: 40px;
}
.HSM_InstafeedAln {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.HSM_InstafeedCardBox {
    width: 100%;
    display: block;
    background: var(--white-color);
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    overflow: hidden;
}
.HSM_InstafeedCardBox img,
.HSM_InstafeedCardBox video {
    display: block;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
}
/*.HSM_InstaFeed .KSM_AllBlgLeftCard {
    background: transparent;
    box-shadow: none;
}*/

/* insta feed section css end */

/* terms & Conditions page css start */

.KSM_TermsConditionsContent {
    background: var(--third-gray);
    padding-block: 60px;
}
.KSM_TermsConditionsMain h2 {
    text-align: center;
}
.KSM_TermsConditionsMain h6 {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 10px;
}
.KSM_TermsConditionsMain h5 {
    text-transform: uppercase;
}
.KSM_TermsConditionsMain ul {
    list-style: none;
}
.KSM_TermsConditionsMain ul li {
    list-style: disc;
    margin-bottom: 10px;
    margin-left: 15px;
}

/* terms & Conditions page css end */

/* HSM offline page css start */

.KSM_HSMPgAbout {
    background: var(--third-gray);
    padding-block: 60px;
}
.KSM_HSMPgAbout h2 {
    text-align: center;
}
.KSM_HSMPgAbout h6 {
    position: relative;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 45%;
    margin-inline: auto;
    display: block;
    text-align: center;
}
.KSM_HSMPgAbout h6::before {
    left: 50%;
    transform: translateX(-50%);
}
.KSM_HSMPgAbout p {
    text-align: center;
    margin-block-start: 25px;
}
.KSM_HSMPgAboutCard {
    width: calc(20% - 10px);
    background: var(--white-color);
    box-shadow: 0px 0px 5px 0 var(--secondary-gray);
    display: block;
    padding: 15px;
    border-radius: 10px;
    margin-inline: 5px;
    margin-block: 5px;
}
.KSM_HSMPgAboutMain {
    display: flex;
    gap: 0;
    flex-wrap: wrap;
    justify-content: center;
}
.KSM_HSMPgAboutCardInner p {
    margin-top: 0;
}
.HSM_WhyChooseHSM ul {
    display: block !important;
}
span.discountedPriceText {
    font-size: 12px;
    color: var(--primary-orange);
}
span.batchTimings {
    color: var(--primary-yellow);
    font-weight: 500;
}
.HSM_WhyChooseHSM .HSM_MasteryCourseBoxCard p {
    color: var(--primary-black);
}
.HSM_HSMCourseOffering .HSM_MasteryCourseListing {
    margin-bottom: 0;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 20px;
}
.HSM_HSMCourseOffering .HSM_MasteryCourseListing:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}
.HSM_HSMCourseOffering .HSM_MasteryCourseListing h4 {
    text-transform: uppercase;
    color: var(--primary-black);
    display: inline-block;
    border-bottom: 3px solid var(--primary-yellow);
}
.HSM_HSMCourseOffering .HSM_MasteryCourseListing p {
    text-align: left;
    margin-bottom: 35px;
}

/* HSM offline page css end */

/* 404 page css start */

.KSM_404Main .HSM_HeaderDemoBtn {
    max-width: 180px;
    margin-inline: auto;
    margin-top: 40px;
}
.KSM_404Main .HSM_HeaderDemoBtn a.ankrBtn {
    padding: 15px;
}

/* 404 page css end */