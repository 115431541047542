@media (max-width: 1023px) {
	.HSM_HeaderMenusMobile {
	    display: flex;
	    align-items: center;
	    justify-content: end;
	}
	.HSM_HeaderMenus {
		display: none;
	}
	.HSM_Header .HSM_HeaderDemoBtn {
	    display: none;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContent {
	    position: static;
	    display: none;
	    transform: translateY(0);
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContent.openMenu {
	    display: block;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContent.openMenu:after {
	    left: 50%;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContentAln {
	    display: flex;
	    flex-wrap: wrap;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContentAln .HSM_HeaderCoursesContentCols {
	    width: 50%;
	    margin-block: 10px;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContentCols ul li a {
	    color: var(--primary-black);
	    text-align: left;
	    padding-block: 5px;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContentCols ul {
		margin-top: 0;
	}
	.HSM_HeaderMenusMobileClosed ul li {
	    border-bottom: 1px solid var(--secondary-bg-color);
	}
	.HSM_HeaderMenusMobileClosed ul li:last-child {
		border-bottom: 0;
	}
	.HSM_HeaderMenusMobileClosed .HSM_HeaderCoursesContentCols ul li a.active {
	    color: var(--primary-yellow);
	}
	.HSM_FooterAln {
	    flex-wrap: wrap;
	}
}

@media (max-width: 991px) {

	h1 {
		font-size: 58px;
	}
	h2 {
		font-size: 42px;
	}

	.HSM_Training_Center h6 {
    max-width: 61% !important;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading {
    max-width: 350px !important;
    margin-bottom: 30px;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading2 {
    max-width: 305px !important;
}
.HSM_WCU h6 {
    max-width: 180px;
}
.row.HSM_Latest_BlogAln {
    flex-wrap: wrap;
}
.HSM_Latest_BlogCard {
    width: 100%;
    margin-block: 15px;
}
.HSM_Latest_BlogCard:last-child {
    margin-bottom: 0;
}
.HSM_Latest_BlogCardInner {
    min-height: auto;
}
.HSM_Latest_BlogCardInnerContent h6 {
    max-width: unset;
}
.HSM_FooterCols {
    width: 100%;
    margin-bottom: 40px;
}
.HSM_FooterCols:last-child {
	margin-bottom: 0;
}
.HSM_FooterCols h6 {
    max-width: 135px;
    margin-inline: auto;
}
.HSM_FooterCols ul li {
    justify-content: center;
}
.HSM_FooterSocialMedia p {
    text-align: center;
}
.SocialMedia ul {
    justify-content: center;
}
.KSM_HSMPgAbout h6 {
    max-width: 100%;
}
.KSM_HSMPgAboutCard {
    width: calc(33% - 10px);
}
.HSM_About_Vision p {
    text-align: center;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li {
    width: calc(50% - 13px);
}
.KSM_AllBlogAln {
    flex-wrap: wrap;
}
.KSM_AllBlogLeft {
    width: 100%;
    margin-bottom: 30px;
}
.KSM_AllBlogRight {
    width: 100%;
}
.HSM_TuitionsCols {
    width: 100%;
}
.HSM_WesternVocalsGroupAdv .HSM_CoursesCards {
    min-height: unset;
}
.HSM_Course_particularContent {
    gap: 35px;
}
.HSM_Course_paricularSelectedCardcols {
    width: 100%;
    margin-bottom: 15px;
}
.HSM_Course_paricularSelectedCardcols:last-child {
    margin-bottom: 0;
}
.HSM_Course_paricularSelectedSolo ul li span.HSM_Course_particularListTitle, 
.HSM_Course_paricularSelectedGroup ul li span.HSM_Course_particularListTitle {
    width: 180px;
}

}

@media (max-width: 767px) {

h1 {
		font-size: 46px;
	}
	h2 {
		font-size: 36px;
	}
	h4 {
    font-size: 26px;
}
.HSM_AboutProfileBannerCols {
    width: 100%;
    margin-block: 15px;
}
.HSM_BankDetailsLeftInner {
    gap: 0;
    flex-wrap: wrap;
}
.HSM_BankDetailsLeftInner .HSM_BankDetailsColsCard {
    margin-block-end: 15px;
    width: 100%;
}
.HSM_BankDetailsLeftInner .HSM_BankDetailsColsCard:last-child {
    margin-block-end: 15px;
}
.KSM_AllBlogRightCardInner {
    align-items: center;
}
	.HSM_Header .HSM_HeaderLeft img {
    max-width: 60px;
}
.KSM_BannerTagsAln {
    padding: 15px;
    gap: 10px;
}
.KSM_BannerTagsImg {
    max-width: 40px;
}
.HSM_MasteryCourseBoxAlgn {
    flex-wrap: wrap;
    justify-content: center;
}
.HSM_MasteryCourseBox {
    width: 50%;
    margin-bottom: 30px;
}
.HSM_MasteryCourseBox:last-child {
    margin-bottom: 0;
}
.HSM_Training_Center h6 {
    max-width: 75% !important;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading {
    max-width: 290px !important;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading2 {
    max-width: 255px !important;
}
.HSM_CoursesCards {
    height: auto;
}
.inputTwoFieldsAln {
    gap: 0;
    flex-wrap: wrap;
}
.inputTwoFieldsAln .inputtypes {
    width: 100%;
}
.HSM_ContactUsAln {
    flex-wrap: wrap;
}
.HSM_ContactUsLeft,
.HSM_ContactUsRight {
    width: 100%;
}
.HSM_Course_paricularSelectedSolo ul li span.HSM_Course_particularListTitle, 
.HSM_Course_paricularSelectedGroup ul li span.HSM_Course_particularListTitle {
    display: block;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar p {
    width: 100%;
}

}

@media (max-width : 568px) {
	.HSM_HeaderMenusMobile span.bars {
	    max-width: 30px;
	}
	.bar1, .bar2, .bar3 {
	    margin-block-end: 6px;
	    height: 3px;
	}
	.HSM_ContactIconFixed {
	    left: 15px;
	    width: 40px;
	    height: 40px;
	    bottom: 70px;
	}
	.HSM_ContactIconFixed a i {
	    font-size: 22px;
	}
	.FixedWhatsAppButton {
	    left: 15px;
	    width: 40px;
	    height: 40px;
	}
	.FixedWhatsAppButton li button {
	    font-size: 28px;
	}
    .HSM_MasteryCourseBoxCard h6 {
        font-size: 16px;
    }
    .HSM_InstafeedCards {
        width: 50%;
    }
}

@media (max-width: 480px) {
	.HomeBanner_Slider button.slick-arrow.slick-prev {
    left: 5px;
}
.HomeBanner_Slider button.slick-arrow.slick-next {
    right: 5px;
}
h1 {
    font-size: 38px;
}
h2 {
	font-size: 30px;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 24px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
.HSM_Training_Center h6 span {
    font-size: 18px;
}
.HSM_Training_Center h6 {
    max-width: 100% !important;
    text-align: center;
}
.HSM_Training_Center h4 {
    font-size: 26px;
}
.HSM_Training_CenterDesc p {
    font-size: 20px;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading2 {
    max-width: 100% !important;
    text-align: center;
}
.HSM_Tuitions h6.HSM_Tuitions_Subheading {
    max-width: 100% !important;
    text-align: center;
}
.HSM_TuitionsColsInner .HSM_CoursesCards {
    height: auto;
    padding: 20px;
}
.HSM_WCU_Tabs {
    gap: 0;
    flex-wrap: wrap;
}
.HSM_WCU_TabButtons {
    width: 100%;
}
.HSM_WCU_TabContent {
    width: 100%;
    margin-block: 30px;
}
.HSM_WCU_Tabs .HSM_CoursesCards {
    min-height: unset;
}
.HSM_TestimonialsCardIcon {
    max-width: 100px;
    max-height: 100px;
}
.HSM_TestimonialsCard p.HSM_TestimonialName {
    font-size: 18px;
}
.HSM_Testimonials {
    padding-bottom: 120px !important;
}
.HSM_Testimonials_Cols {
    padding-inline: 0;
}
.HSM_AboutUs .HSM_MasteryCourseListing ul li {
    width: 100%;
    padding-right: 0;
}
.KSM_Banner h1 {
    max-width: 85%;
    display: block;
    margin-inline: auto;
}
.KSM_BannerAlg {
    top: 120px;
    flex-wrap: wrap;
}
.KSM_BannerTags {
    width: 50%;
    margin-bottom: 10px;
}
.KSM_BannerTagsAln {
    padding: 10px;
    justify-content: center;
}
.HSM_CoursesSlider {
    padding-block: 40px;
}
.HSM_CoursesBg {
    padding-bottom: 110px !important;
    padding-top: 0px !important;
}
.KSM_AllBlogMain {
    padding-block: 40px;
}
.HSM_FooterCols h6 {
    max-width: 115px;
}
.HSM_FooterBtmLogo a {
    max-width: 100px;
}
.HSM_FooterBtm {
    padding-top: 40px;
}
.KSM_TermsConditionsContent {
    padding-block: 40px;
}
button.HSM_Faq_Accordian_Question {
    font-size: 16px;
    line-height: 26px;
}
.KSM_HSMPgAboutCard {
    width: calc(50% - 10px);
}
.HSM_WhyChooseHSM .HSM_MasteryCourseBoxCard {
    min-height: unset;
}
.HSM_CoursePartiular {
    padding-bottom: 0 !important;
}
.HSM_AboutProfileBannerCols h6 span.HSM_AboutProfileSpan2 {
    font-size: 18px;
}
.HSM_ApplicationForm .HSM_MasteryCourseListing ul li {
    width: calc(100% - 13px);
}
.HSM_ApplicationForm .HSM_ContactUsRightInner p.HSM_ApplicationFormBankInst {
    font-size: 16px;
}
.HSM_Course_particularDescInner h6 {
    font-size: 18px;
}
.HSM_Course_particularSelect h5 {
    font-size: 24px;
}
.HSM_Course_paricularSelectedSolo h6, .HSM_Course_paricularSelectedGroup h6 {
    font-size: 18px;
}
.HSM_Course_particularContent {
    flex-wrap: wrap;
}
.HSM_Course_paricularSelectedSoloInstructionsSinStar {
    width: 100%;
}
.HSM_FooterAln {
    padding-bottom: 40px;
}
.HSM_MasteryCourseBox {
    width: 100%;
}
.HSM_InstafeedCards {
    width: 100%;
}

}

@media (max-width: 414px) {
	h4 {
    font-size: 22px;
}
.HSM_ContactDetails ul li {
    flex-wrap: wrap;
}
	.KSM_BannerTagsCont h6 {
    font-size: 15px;
}
.KSM_HSMPgAboutCard {
    width: calc(100% - 10px);
}
.KSM_ContactPage_Banner .container {
    left: 35% !important;
}
.HSM_Course_paricularSelectedCardcolsInnerPayLeft {
    width: 50%;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight {
    width: 50%;
}
.HSM_HeaderLeft {
    width: 30%;
}

}

@media (max-width: 375px) {
	.KSM_BannerTags {
    width: 70%;
}
.KSM_BannerTagsAln {
     padding: 10px; 
}
.HSM_MasteryCourseBox {
    width: 100%;
}
.HSM_Course_paricularSelectedCardcolsInnerPay {
    flex-wrap: wrap;
}
.HSM_Course_paricularSelectedCardcolsInnerPayLeft {
    width: 100%;
    margin-bottom: 30px;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight {
    width: 100%;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight .HSM_Course_paricularSelectedCardcolsInnerPayBtn {
    margin-left: auto;
    max-width: unset;
    text-align: center;
}
.HSM_Course_paricularSelectedCardcolsInnerPayRight .HSM_HeaderDemoBtn {
    margin-inline: auto; 
}

}