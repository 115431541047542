@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Black.eot');
    src: url('../fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Black.woff2') format('woff2'),
        url('../fonts/Rubik-Black.woff') format('woff'),
        url('../fonts/Rubik-Black.ttf') format('truetype'),
        url('../fonts/Rubik-Black.svg#Rubik-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-ExtraBold.eot');
    src: url('../fonts/Rubik-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-ExtraBold.woff2') format('woff2'),
        url('../fonts/Rubik-ExtraBold.woff') format('woff'),
        url('../fonts/Rubik-ExtraBold.ttf') format('truetype'),
        url('../fonts/Rubik-ExtraBold.svg#Rubik-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Bold.eot');
    src: url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik-Bold.ttf') format('truetype'),
        url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-SemiBold.eot');
    src: url('../fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('../fonts/Rubik-SemiBold.woff') format('woff'),
        url('../fonts/Rubik-SemiBold.ttf') format('truetype'),
        url('../fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Light.eot');
    src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Light.woff2') format('woff2'),
        url('../fonts/Rubik-Light.woff') format('woff'),
        url('../fonts/Rubik-Light.ttf') format('truetype'),
        url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-Light.eot');
    src: url('../fonts/CormorantGaramond-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-Light.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-Light.woff') format('woff'),
        url('../fonts/CormorantGaramond-Light.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-Light.svg#CormorantGaramond-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-Bold.eot');
    src: url('../fonts/CormorantGaramond-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-Bold.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-Bold.woff') format('woff'),
        url('../fonts/CormorantGaramond-Bold.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-Bold.svg#CormorantGaramond-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-Medium.eot');
    src: url('../fonts/CormorantGaramond-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-Medium.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-Medium.woff') format('woff'),
        url('../fonts/CormorantGaramond-Medium.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-Medium.svg#CormorantGaramond-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-SemiBold.eot');
    src: url('../fonts/CormorantGaramond-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-SemiBold.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-SemiBold.woff') format('woff'),
        url('../fonts/CormorantGaramond-SemiBold.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-SemiBold.svg#CormorantGaramond-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-Regular.eot');
    src: url('../fonts/CormorantGaramond-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-Regular.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-Regular.woff') format('woff'),
        url('../fonts/CormorantGaramond-Regular.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-Regular.svg#CormorantGaramond-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
    box-sizing: border-box;
}

body {
    font-family: 'Rubik';
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body.mobileMenuActive {
  overflow: hidden;
  height: 100%;
  position: fixed;
  width: 100%;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: 'Rubik';
}
.container {
    max-width: 1330px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

[class*="col-"] {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

.col-5-eq {width: 20%;}

:root {
    --black-color: #000;
    --white-color: #fff;
    --primary-body: #0b0c10;
    --primary-pink: #a51047;
    --primary-heading-color: #cccacb;
    --secondary-bg: #1c0b13;
    --primary-black: #252525;
    --primary-yellow: #FFAA32;
    --primary-orange: #EF543E;
    --primary-gray: #fffbfb;
    --secondary-gray: #e5e5e5;
    --third-gray: #f3f3f3;
    --primary-body-bg: #141c3e;
    --primary-text-color: #ffffffcc;
    --secondary-bg-color: #ffffff26;
}
h1,h2,h3,h4,h5,h6,p,ul,li {
    line-height: normal;
    padding: 0;
    margin: 0;
    letter-spacing: 0;
}
a,p {
    font-family: 'Rubik';
    letter-spacing: 0;
}
h1 {
    font-size: 64px;
    color: var(--primary-black);
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    text-transform: uppercase;
}
h2 {
    font-size: 46px;
    color: var(--primary-black);
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    text-transform: uppercase;
}
h3 {
    font-size: 38px;
    color: var(--primary-black);
    font-family: 'Cormorant Garamond';
    text-transform: uppercase;
}
h4 {
    font-size: 32px;
    color: var(--primary-black);
}
h5 {
    font-size: 22px;
    color: var(--primary-black);
}
h6 {
    font-size: 18px;
    color: var(--primary-black);
}
p, li, a {
    font-size: 16px;
    line-height: 26px;
    color: var(--primary-black);
}
a {
    display: block;
}
::selection {
  background: var(--primary-yellow);
  color: var(--white-color);
}
.ankrBtn {
    background: linear-gradient(90deg, var(--primary-yellow) 60%, var(--primary-orange) 93%);
    color: var(--white-color);
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 7px;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    background-size: 200%;
    background-position: right;
}
.ankrBtn i {
    margin-left: 5px;
}
.ankrBtn:hover {
    background-position: left;
}

/* slider css start */

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
    display: block;
    color: var(--white-color);
    z-index: 9;
    width: 40px;
    height: 40px;
    background: var(--primary-black);
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    top: calc(100% + 40px);
}
button.slick-arrow.slick-prev {
    left: calc(50% - 45px);
}
button.slick-arrow.slick-next {
    right: calc(50% - 45px);
}
.slick-prev:before, 
.slick-next:before {
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 40px;
    opacity: 1;
    color: var(--white-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before {
    content: '\2190';
}
.slick-next:before {
    content: '\2192';
}
.slick-slide {
  padding: 0 7px;
}
/*.slick-slide img {
    width: 100%;
    height: 100%;
    display: block;
}*/

/* slider css end */

input, 
textarea,
select {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    outline: none;
    font-family: 'Rubik';
    outline: none;
    margin-inline: 0;
    border: 1.5px solid var(--secondary-bg-color);
    color: var(--white-color);
    background: transparent;
    padding: 15px;
    border-radius: 10px;
}
textarea {
    resize: vertical;
}
input:focus, 
textarea:focus,
select:focus {
    border: 1px solid var(--primary-yellow);
}

button {
    background: var(--black-color);
    text-transform: uppercase;
    outline: none;
    color: var(--white-color);
    display: block;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Rubik';
    outline: none;
    border: none;
}

input::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder  {
  color: var(--white-color);
  font-family: 'Rubik';
  font-size: 16px;
}

input:-ms-input-placeholder, 
textarea:-ms-input-placeholder {
  color: var(--white-color);
  font-family: 'Rubik';
  font-size: 16px;
}

input::placeholder, 
textarea::placeholder {
  color: var(--white-color);
  font-family: 'Rubik';
  font-size: 16px;
}

input:-webkit-autofill {
    font-size: 16px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--white-color);
    transition: background-color 5000s ease-in-out 0s;
}

.HSM_Course_paricularSelectedSolo ul::-webkit-scrollbar {
  width: 3px;
}
.HSM_Course_paricularSelectedCardcolsInner p::-webkit-scrollbar {
    width: 0;
}
.HSM_WhatWillBeTaught ul::-webkit-scrollbar {
    width: 0;
}

/* Track */
.HSM_Course_paricularSelectedSolo ul::-webkit-scrollbar-track,
.HSM_Course_paricularSelectedCardcolsInner p::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.HSM_Course_paricularSelectedSolo ul::-webkit-scrollbar-thumb,
.HSM_Course_paricularSelectedCardcolsInner p::-webkit-scrollbar-thumb {
  background: var(--primary-yellow);
}

.KSM_Banner::before,
.HSM_ContactUs::before,
.HSM_Faq::before,
.HSM_MasteryCourse::before,
.HSM_WCU::before,
.HSM_AboutProfileBanner::before,
.KSM_IndianClassical_Banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.HSM_MasteryCourse .container,
.HSM_Faq .container,
.KSM_Banner .container,
.HSM_Testimonials .container,
.HSM_ContactUs .container,
.HSM_WCU .container,
.HSM_AboutProfileBanner .container,
.KSM_IndianClassical_Banner .container {
    position: relative;
    z-index: 2;
}

.HSM_HeaderCoursesContentCols h6:before,
.HSM_FooterCols h6:before,
.HSM_ContactUsLeftInner h6:before,
.HSM_MasteryCourseListing h6:before,
.HSM_MasteryCourseListing ul li h6.listingTitle:before,
.HSM_Tuitions h6:before,
.HSM_WCU h6:before,
.HSM_CoursesCards h6:before,
.KSM_BannerTagsCont h6:before,
.HSM_AboutProfileBannerCols h6:before,
.HSM_BankDetailsColsCard h6:before,
.KSM_TermsConditionsMain h6:before,
.KSM_AllBlgLeftCardInnerContent h6:before,
.KSM_AllBlogRightCardInnerContent h6:before,
.KSM_HSMPgAbout h6:before,
.KSM_SingleBlogCardInner h6:before,
.HSM_Course_particularListTitle:before {
    content: '';
    background: var(--primary-yellow);
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 40px;
    height: 2px;
}

.KSM_AdmFormBanner .container {
    top: 37% !important;
}
.KSM_AboutPage_Banner .container {
    left: 30% !important;
}
.KSM_ContactPage_Banner .container {
    left: 28% !important;
}

.KSM_IndianClassical_Banner .container h1 {
    left: -26% !important;
}

.react-datepicker-popper {
    z-index: 3 !important;
}
.react-datepicker-wrapper {
    display: block !important;
}
.react-datepicker__time-container {
    width: 100px !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px !important;
}

